<template>
  <div>
    <MyHeader />
    <main id="content">
      <div class="container">
        <br class="mb-5">
        <div class="row align-items-center justify-content-center">
          <div class="col-12">
            <h4>10. POLÍTICAS DE PRIVACIDAD.</h4>

            <p>10.1 HBL le solicitará a los usuarios y recabará información básica que comprende:</p>

            <p>1. Nombre completo</p>
            <p>2. Correo electrónico</p>
            <p>3. Dirección IP</p>
            <p>4. Número de teléfono</p>
            <p>5. Ciudad o domicilio</p>
            <p>6. Cualquier dato libremente revelado por el usuario.</p>

            <p>10.2 El usuario reconoce que ninguna de esa información es sensible y por tanto de libre acceso. Cualquier información revelada a la plataforma es una autorización de divulgación de dicha información y un descargo de responsabilidad del usuario a la plataforma por dicho uso.</p>

            <p>10.3 El usuario no revelará datos sensibles protegidos por la ley; de realizarlo, se expone por su negligencia a que dichos datos puedan ser descubiertos o revelados por terceros, descargando de toda responsabilidad a HBL.</p>

            <p>10.4 La política de acceso, cookies o datos de IP, localización y similares, se tratará conforme a los usos y costumbres habitualmente aceptados del internet.</p>

            <p>11. OBLIGACIONES PARTICULARES DE LOS ANFITRIONES.</p>

            <p>11.1 El Anfitrión es el exclusivo responsable de determinar sus obligaciones de declarar, cobrar, remitir o incluir en sus tarifas por anuncio los impuestos aplicables según su tipo de alquiler, el impuesto de ocupación, de turista u otro impuesto por visitante o impuestos sobre la renta que pudieran ser de aplicación ("Impuestos").</p>

            <p>11.2. Se reconoce que existe la posibilidad que la administración tributaria pueda requerir que recopilemos la debida información fiscal de nuestros anfitriones.</p>

            <p>11.3. Automáticamente el usuario al crear su perfil en la plataforma de HBL  acepta que cualquier reclamación o requerimiento de acción relativa a la facilitación por parte de HBL del cobro y transferencia de impuestos, activará un deber de cooperación con las autoridades relativas a esas pesquisas.</p>

            <p>ANEXO A. CONDICIONES DE LAS RESERVAS Y DEL ALQUILER DE LAS VIVIENDAS.-</p>

            <p>SOBRE EL ALQUILER DE LAS VIVIENDAS O INMUEBLES.-</p>

            <p>1. <b>Contrato vinculante.</b> Una vez el inquilino y el anfitrión entren en relación, lo que ocurrirá cuando se remita por la vía electrónica o el pago de la vivienda o el depósito de alquiler (según las políticas individuales de alquiler por vivienda que se indica en su propia oferta, que será según corresponda y se indique en la oferta-precio indicado por las fechas), el acuerdo de alquiler de vivienda se convierte en vinculante y por consiguiente, anfitrión e inquilino se obligan a cumplir no solo con los términos aquí indicados, sino con las obligaciones que se detallan en esta sección de “alquiler de viviendas” así como en la sección CONDICIONES ESPECÍFICAS DE LA VIVIENDA, relativas de manera específica a la vivienda o inmueble escogido por el inquilino, en adición a los términos y condiciones generales de HBL.</p>

            <p>1.1 El anfitrión deberá proveer en un plazo no mayor de 24 horas a contar del momento en que recibe la solicitud de reserva de un inquilino a través de la plataforma HBL, ya sea de manera directa en su cuenta HBL o enviando un correo electrónico a la plataforma que confirme la disponibilidad del alquiler, de modo que se realice la reserva y su posterior pago para el disfrute de la misma por el inquilino. Caso de Book Now</p>

            <p>1.2 Una reserva aceptada por el anfitrión y que no sea honrada, dará lugar no solamente a los reembolsos y penalidades antes indicadas, sino que HBL y el inquilino podrán requerir por la vía que entiendan pertinente en derecho, el resarcimiento de cualquier daño y perjuicio sufrido, incluyendo el daño a la imagen y la pérdida de la oportunidad.</p>

            <p>2. <b>Obligación del anfitrión.</b> Al ser un contrato de alquiler vinculante, EL ANFITRIÓN se obliga a respetar el uso de la vivienda a favor del INQUILINO durante el plazo convenido, siempre que se haya efectuado el pago del alquiler. La violación a esa obligación conllevará el reembolso de todos los gastos del viaje de EL INQUILINO, incluyendo los boletos aéreos, costos de traslado al local y costos de alquiler de vivienda alternativa por no poder ocupar esa, más el 50% de esos valores a título de indemnización.</p>

            <p>3. <b>Pago.</b> HBL debe recibir el pago de la tarifa completa al momento de que el anfitrión acepte la reserva del inquilino. Los anfitriones podrán definir una política de pago escalonada, pero nunca será inferior al 50% contra alquiler y 50% a más tardar 1 día antes de la entrada a la vivienda. Si la reserva es con menos de 30 días de antelación, el pago será completo. No poner tan rugido y que queda a discreción de HBL esta política.</p>

            <!-- eslint-disable-next-line no-irregular-whitespace -->
            <p>3.1 Los pagos atrasados ​se aceptarán únicamente a discreción de el anfitrión, ya que la falta de pago conlleva la pérdida de la reserva y del pago hecho hasta el momento, salvo política de cancelación distinta adoptada por el anfitrión en relación con su inmueble.<b> No se le permitirá en la propiedad a menos que el pago se haya recibido en su totalidad.</b></p>

            <p><b>4. Modificaciones, cancelaciones y reembolsos de reservas.</b></p>

            <p>4.1. Las reservas podrán ser canceladas por los usuarios en cualquier momento, HBL  realizará el reembolso al cliente con arreglo a dicha política de cancelación de cada anfitrión en sus propiedades, las que se detallan en los datos de la reserva.</p>

            <p>4.2. No obstante, HBL  podrá (i) mantener bloqueadas o indisponibles las fechas de la reserva cancelada en ese anuncio, y/o (ii) imponer una tarifa de cancelación, salvo que el anfitrión tenga un motivo válido para cancelar la reserva o sospechas legítimas respecto al comportamiento del inquilino como por ejemplo, como lo es el no cumplimiento de las políticas de los presentes términos y condiciones, no cumplimiento de los requisitos exigidos y publicados en el perfil del anuncio por el Anfitrión así como también el no respeto al bien común de la Propiedad.</p>

            <p>4.3. En los casos de eventos de fuerza mayor, que impidan al huésped ejercer el uso, goce y disfrute de la reserva los anfitriones podrán modificar o cancelar un servicio de alquiler en previo acuerdo con el mismo. Si se produce un cambio sustancial en el itinerario o tiene que cancelarse el servicio de anfitrión, HBL  trabajará con el Anfitrión para ofrecer a los inquilinos una fecha alternativa para el servicio del alquiler por el anfitrión, el reembolso correspondiente o una nueva reserva, o lo colocará en las primeras listas de prioridad cuando se abra una nueva oportunidad de fechas disponibles para la misma propiedad.</p>

            <p>4.4 En circunstancias especiales, HBL  podrá decidir, a su exclusivo criterio, que resulta necesario cancelar una reserva confirmada y realizar el reembolso correspondiente y adoptar las respectivas decisiones de pago. Esto puede ocurrir por motivos establecidos en la Política de HBL o (i) cuando HBL estime de buena fe, y teniendo en cuenta los legítimos intereses de ambas partes, que resulta necesario para evitar un daño importante a HBL a otros miembros, a terceros o a bienes materiales, o (ii) por cualquiera de los motivos previstos en los presentes términos. QUE PASA EN EVENTOS DE DANOS EN APARMENTOS Y QE ESTEMOS LLENOS SIN PODER MUDAR Y FUERZA MAYO.</p>

            <p>4.5. Si un usuario sufre un contratiempo de viaje o cambio de itinerario sin su consentimiento con arreglo a la Política de Reembolso al Huésped, HBL  podrá decidir, reembolsar al Huésped la totalidad o parte de las tarifas totales de conformidad con las Política de Reembolso al Huésped.</p>

            <p>4.6. En los casos donde el usuario desee enviar o solicitar dinero de reembolsos, servicios de Anfitrión adicionales, quejas o comentarios relacionados con la reserva, podrá hacerlo mediante la sección de quejas.</p>

            <p>4.7 HBL podrá poner a disposición del alquiler de algunas viviendas, un seguro de cancelación del viaje, según se permita o lo acepte para ciertos casos, la entidad aseguradora, que pondrá previamente a disposición del usuario, los términos y condiciones de su servicio.</p>

            <p><b>5. Depósito de seguridad.</b> Cada alquiler conllevará un depósito de seguridad. Para eso se debe proporcionar a HBL una tarjeta de crédito válida para actuar como garantía por cualquier pérdida o daño a la propiedad (incluido su contenido) durante el período de alquiler. HBL puede cargar dicha tarjeta de crédito por el monto de estas pérdidas o daños.</p>

            <p>5.1 Antes del cargo por la pérdida o daño, HBL notificará y discutirá con el anfitrión y el inquilino. Si está obligado a pagar un depósito de seguridad en efectivo adicional (indicado en sus detalles de pago), se le reembolsará el depósito después de recibir la autorización del propietario. Los reembolsos o aplicaciones de garantías se harán entre 2 y 4 semanas. En algunos destinos puede tomar varios meses antes de que el anfitrión haya autorizado el pago del reembolso.</p>

            <p><b>6. Identificación de los inquilinos.</b> Antes de proceder con la reserva, el inquilino debe proporcionar la identificación de todas las personas que viajan, ya que las medidas de seguridad varían de una región a otra. En caso de que se niegue a proporcionar la identificación adecuada, acepta el riesgo de que se le niegue el acceso a la propiedad. Todo documento de identidad proporcionado será confidencial y no será almacenada, transferido o utilizada por ningún tercero. En el caso de una alerta de emergencia o seguridad, la información del viajero puede divulgarse por razones de seguridad o para cumplir con las autoridades locales habilitadas por la ley para obtener esos datos. Se le podrá.</p>

            <p><b>7. Daños a la propiedad.</b> El inquilino será el único responsable de todos los daños, perjuicios, lesiones y pérdidas en relación con la ocupación o el uso de la propiedad alquilada y de sus acompañantes o invitados. Debe asegurarse de que la propiedad y todos los muebles, accesorios y efectos permanezcan en la misma condición y ubicación que cuando realizó el ingreso (check-in). SEGURO ANFITRION.</p>

            <p><b>8. Uso de la propiedad.</b> El inquilino no puede llevar a cabo ninguna actividad ilegal en la propiedad o cualquier actividad que pueda ser razonablemente considerada una molestia o molestia para el anfitrión o los vecinos.  FIESTAS Y BULLA</p>
            <p>8.1 A menos que el anfitrión o HBL autoricen previamente, no puede traer ningún animal a la propiedad.  A MENOS QUE SEA PET FRIENDLY.</p>

            <p>8.2 El inquilino se compromete a velar por el fiel cumplimiento de los reglamentos sanitarios, haciéndose responsable de las infracciones correspondientes.</p>

            <p>8.3 El número total de invitados no puede exceder el número identificado como máxima ocupación en la oferta del inmueble.</p>

            <p>8.4 Si la propiedad ofrece o tiene más habitaciones de las que ha alquilado, usted acepta que el anfitrión decida cuáles de las habitaciones de la propiedad estarán disponibles para usted y cuáles permanecerán sin usar. Borrar</p>


            <p>8.5 No se permiten fiestas, bodas, recepciones, actividades abiertas al público, reuniones con terceros ajenos a los inquilinos registrados. Cualquier excepción requerirá un permiso escrito por adelantado, un comprobante de seguro y otros requisitos y tarifas según se considere necesario. 8</p>

            <p>8.6 El inquilino velará y cuidará el propiedad alquilada como una persona prudente y razonable. No realizará actos que atenten contra su integridad, por lo tanto, se comportará con plena prudencia y cautela.</p>

            <p><b>9. Fuerza mayor.</b> HBL ni el anfitrión  o propietario serán responsables por daños, pérdidas o lesiones causadas por condiciones fuera del control de éstos, incluyendo, sin limitación, cualquier incendio, inundación, huracán, tsunami, guerra, revolución, terrorismo o cambiar a cualquier ley, regulación o política gubernamental; es decir, cualquier caso fortuito, fuerza mayor o causa extraña exoneratoria.</p>

            <p><b>10. Situaciones con la propiedad.</b> El inquilino debe notificar inmediatamente a HBL o al anfitrión o al administrador de la propiedad in situ, cualquier problema relacionado con el inmueble. HBL se esforzará razonablemente para solucionar los problemas, pero no considerará las reclamaciones presentadas después de que haya salido de la propiedad (check out).</p>

            <p><b>11. Terminación anticipada.</b> El alquiler podrá ser terminado de inmediato si el inquilino incumple uno o cualquiera de los términos del alquiler. Deberá desocupar la propiedad de forma inmediata. Su permanencia en ella se considerará una violación directa al derecho de propiedad y un traspaso ilegal de propiedad privada.</p>

            <p><b>12. Condiciones particulares de la vivienda.</b> El inquilino ha leído y aceptado el acápite de “condiciones particulares de la vivienda” indicados en la página de oferta de la vivienda reservada, al hacer click en su casilla.</p>

            <p><b>ANEXO B. FORMULARIO DE VINCULACIÓN DEL ANFITRIÓN.</b></p>

            <p>1. NOMBRE DEL PROPIETARIO</p>

            <p>2. IDENTIFICACIÓN DEL INMUEBLE</p>

            <p>3. CARACTERISTICAS DEL INMUEBLE</p>

            <p>4. IDENTIFICACIÓN DEL INMUEBLE</p>

            <p>5. DESIGNACIÓN CATASTRAL DEL INMUEBLE</p>

            <p>6. UBICACIÓN DEL INMUEBLE</p>

            <p>7. DETALLES DE LOS ESPACIOS DISPONIBLES Y FOTOS.</p>

            <p>8. SERVICIOS DISPONIBLES: AIRE, LAVADORA, SECADORA, PLANCHA, INTERNET, TV, COCINA EQUIPADA, TIPOS DE MUEBLES, TIPOS DE CAMAS Y DISTRIBUCIÓN, PISCINA, JACUZZI, BBQ, GAZEBO, CUARTO DE SERVICIO, BAÑOS, ETC.</p>

            <p>9. CONDICIONES O REGLAS ESPECÍFICAS DE USO. EJ, NO FUMAR, NO ANIMALES, PISCINA CON REGLA, BBQ CON REGLAS, ATENCIÓN A LOS MENORES, CUIDADOS ESPECIALES, ETC.</p>

            <p>10. PRECIO DE ALQUILER POR DÍA.</p>

            <p>11. PORCENTAJE DE COMISIÓN PARA HBL</p>

            <p>12. PRECIO DE SERVICIO DIARIO DE LIMPIEZA Y CONSERJERÍA – COBRABLE AL INQUILINO.</p>

            <p>13. FORMA DE PAGO DE LA RESERVA. CONDICIONES DE CANCELACIÓN.</p>

            <p>EL ANFITRIÓN acepta que los pagos hacia el por concepto de los alquileres de la vivienda a través de la plataforma se harán de manera mensual. En ese sentido, todos los días treinta (30) de cada mes se hará el corte de los ingresos de ese mes y el pago será remitido según las instrucciones bancarias que figuran en este anexo, durante los primeros quince (15) días del siguiente.</p>

            <p>Recibirán junto con la remisión del pago un reporte detallado de la proveniencia de las RESEVAS los montos, descuentos hechos por HBL por pago a terceros proveedores en el país y ciudad de destino de la vivienda del anfitrión, por manejo, arreglos y compras de la propiedad para mantener los estándares y las ocupaciones en óptimas condiciones. O LO VERA DIGITAL</p>

            <p>Estos pagos se harán basados en las ocupaciones ya realizadas y no en los avances de dineros a rentas futuras.</p>

            <p>En caso que la reserva se tramite a través de la plataforma HBL pero el cliente haya ingresado a través de un portal de renta de casas distinto, el anfitrión acepta que la recepción de pagos, cancelaciones, políticas de uso y reserva se harán de acuerdo  y en base a las aprobadas, exigidas de ese “online travel agency” incluyendo el propio de HBL. En ese sentido, EL ANFITRIÓN ACEPTA que cada online travel agency - vacation rental es y seguirá siendo el responsable de lo que suceda con los pagos y RESEVAS de su canal.</p>

            <p>Es esencial y así lo acepta, asume y reconoce EL ANFITRIÓN que es y será responsable del pago de los impuestos que puedan corresponder por el alquiler de la vivienda o cualquier ingreso relacionado con su propiedad en el país en la cual se encuentra. Reconoce que generalmente las distintas jurisdicciones prevén que los ingresos por alquiler son ingresos tributables en su país y por tanto, EL ANFITRIÓN deberá observar y cuidar del buen manejo en ese orden de ideas; para lo cual, deberá a su costo y de ser necesario, buscar la asistencia o asesoría de un profesional habilitado al efecto.</p>
          </div>
        </div>
        <br>
      </div>
    </main>
    <MyFooter />
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicies',
};
</script>

<style scoped>
</style>
